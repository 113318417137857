#selector-intro {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: -#{$size_base_multiplier * 3};

    > .background-intro {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        opacity: 0.3;
    }
    > .intro-center-card {
        width: $size_base_multiplier * 50;
        padding: $size_base_multiplier * 2;
        border-radius: $size_base_multiplier;
        opacity: 1;
        border: transparent;

        > .ant-card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
            background-color: transparent;

            > .ant-image {
                margin: auto auto $size_base_multiplier * 4 auto;
                > img {
                    width: auto;
                    height: auto;
                    max-width: $size_base_multiplier * 38;
                    max-height: $size_base_multiplier * 8;
                }
            }

            > .text {
                text-align: center;
                color: $colors_text;
                font-size: $font_size_h4;
                > p {
                    white-space: pre-line;
                    font-weight: 500;
                }
            }
            > .ant-btn {
                border-radius: 10px;
                height: $size_base_multiplier * 5;
                width: 100%;
                &:hover {
                    opacity: 1;
                }
                > h6 {
                    color: $colors_bg;
                    margin: auto;
                }
            }
        }
    }
    > .intro-logo {
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: absolute;
        bottom: $size_base_multiplier * 3;
        padding: 0 $size_base_multiplier * 3;
        > .ant-image {
            > img {
                width: auto;
                height: auto;
                max-width: $size_base_multiplier * 8;
                max-height: $size_base_multiplier * 11;
            }
        }
    }
}
