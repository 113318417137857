.steps-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    margin-top: $size_base_multiplier * 4;

    @include respond-to(sm) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
    > .ant-image {
        margin-right: $size_base_multiplier * 2;
        @include respond-to(sm) {
            margin-bottom: $size_base_multiplier * 2;
        }
        > img {
            height: auto;
            width: auto;
            max-width: $size_base_multiplier * 21;
            max-height: $size_base_multiplier * 4;
        }
    }

    > .ant-steps {
        @include respond-to(xs) {
            display: none;
        }
        > .ant-steps-item {
            padding-left: 4px;
        }
        //Step in process
        > .ant-steps-item-process {
            > .ant-steps-item-container {
                > .ant-steps-item-icon {
                    padding-left: 0;
                    > .ant-steps-progress-icon {
                        > .ant-progress {
                            > .ant-progress-inner {
                                > .ant-progress-circle {
                                    display: flex;
                                    align-items: center;
                                    // @include respond-to(sm) {
                                    //     width: 28px;
                                    // }
                                }
                            }
                        }
                        > .ant-steps-icon {
                            vertical-align: text-top;
                        }
                    }
                }
                > .ant-steps-item-content {
                    > .ant-steps-item-title {
                        font-weight: $font_semi_bold;
                    }
                }
            }
        }

        // All steps
        > .ant-steps-item-process,
        .ant-steps-item-wait,
        .ant-steps-item-finish {
            > .ant-steps-item-container {
                @include respond-to(upto-md) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                > .ant-steps-item-icon {
                    // @include respond-to(sm) {
                    //     width: 20px;
                    //     height: 20px;
                    // }
                    margin-right: 6px;

                    > .ant-steps-icon {
                        vertical-align: text-top;
                    }
                }
                > .ant-steps-item-content {
                    flex: 1;
                    > .ant-steps-item-title {
                        // @include respond-to(sm) {
                        //     white-space: pre-line;
                        //     font-size: 13px;
                        //     padding: 0 2px 0 2px;
                        // }
                        &::after {
                            height: 1px;
                        }
                    }
                }
            }
        }
    }
}
