.ant-card-body {
    .stepper-navigator {
        display: flex;
        justify-content: space-between;
        margin-top: $size_base_multiplier * 4;

        > .ant-btn {
            display: flex;
            align-items: center;
            border-radius: 10px;
            @include respond-to(xxs) {
                justify-content: center;
                width: $size_base_multiplier * 8;
            }

            > i::before {
                font-size: 26px;
                font-weight: $font_regular;
                @include respond-to(xxs) {
                    display: none;
                }
            }
            > span {
                font-weight: $font_semi_bold;
                font-size: $font_size_h6;
                @include respond-to(xxs) {
                    font-size: 13px;
                }
            }
            &.ant-btn-primary {
                padding: 0 $size_base_multiplier * 2 0 $size_base_multiplier * 3;
                @include respond-to(xxs) {
                    padding: 0;
                }
                &:hover {
                    opacity: 1;
                }
            }
            &:not(.ant-btn-primary) {
                &:hover {
                    color: #fff;
                }
            }
        }
    }
}
