.summary-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > header {
        display: flex;
        align-items: center;
        height: $summary_offsets_header_height;
        justify-content: space-between;
        margin: $summary_offsets_header_margin_y -#{$size_base_multiplier * 2}
            $summary_offsets_header_margin_y $size_base_multiplier * 6;

        @include respond-to(xs) {
            margin: $size_base_multiplier * 3 -#{$size_base_multiplier * 6} $size_base_multiplier *
                3 $size_base_multiplier;
        }

        > .ant-image {
            margin-right: $size_base_multiplier * 3;
            @include respond-to(sm) {
                margin-bottom: $size_base_multiplier * 2;
            }
            > img {
                height: auto;
                width: auto;
                max-width: $size_base_multiplier * 21;
                max-height: $size_base_multiplier * 4;
            }
        }
        > .secondary-logo {
            display: flex;
            > .ant-image {
                margin-left: $size_base_multiplier * 2;
                > img {
                    width: auto;
                    height: auto;
                    max-width: $size_base_multiplier * 3;
                    max-height: $size_base_multiplier * 4;
                }
            }
        }
    }
    > .summary-content-container {
        padding: 0;
        margin: 0 -17px 0 47px;

        > .ant-card {
            border: none;
            > .ant-card-body {
                padding: 0 $size_base_multiplier * 9;
                height: $summary_content_height;
                overflow-y: scroll;
                overflow-x: hidden;
                margin: 0 -#{$size_base_multiplier * 9} 0 -#{$size_base_multiplier * 9};

                @include respond-to(xs) {
                    padding: 0 $size_base_multiplier * 5;
                }
                > .intro-screen {
                    margin-bottom: $size_base_multiplier * 4;
                    > h1 {
                        text-align: center;
                        color: $colors_text;

                        @include respond-to(xxs) {
                            font-size: $size_base_multiplier * 3;
                        }
                        @include respond-to(xxs-l) {
                            font-size: $size_base_multiplier * 4;
                        }
                        @include respond-to(sm) {
                            font-size: $size_base_multiplier * 6;
                        }
                        @include respond-to(md) {
                            font-size: $size_base_multiplier * 7;
                        }
                        @include respond-to(lg) {
                            font-size: $size_base_multiplier * 8;
                        }
                        @include respond-to(xlg) {
                            font-size: $size_base_multiplier * 9;
                        }
                    }
                    > .intro-image {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-end;
                        background-position: center;
                        background-size: cover;

                        max-width: 100%;
                        position: relative;
                        margin: 0 auto;
                        height: calc(
                            ($summary_content_height - (100px + $size_base_multiplier * 2))
                        );
                        width: calc(
                            calc(($summary_content_height - (100px + $size_base_multiplier * 2))) *
                                2
                        );

                        > * {
                            z-index: 100;
                        }

                        > .intro-scroll {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-bottom: #{-$size_base_multiplier * 4};
                            > p {
                                font-size: 20px;
                                font-weight: bold;
                                text-shadow: 2px 2px $colors_text;
                                margin: 0 0 #{-$size_base_multiplier * 5} 0;
                                color: $colors_bg;

                                @include respond-to(xs) {
                                    font-size: 13px;
                                }

                                @include respond-to(xlg) {
                                    font-size: $size_base_multiplier * 3;
                                }
                            }
                            > i {
                                &.ri-arrow-down-s-line:before {
                                    font-size: $size_base_multiplier * 12;
                                    height: $size_base_multiplier * 6;
                                    color: $colors_bg;

                                    @include respond-to(xs) {
                                        font-size: $size_base_multiplier * 6;
                                    }

                                    @include respond-to(sm) {
                                        font-size: $size_base_multiplier * 9;
                                    }

                                    @include respond-to(md) {
                                        font-size: $size_base_multiplier * 10;
                                    }
                                }
                            }
                        }
                    }
                }
                > .main-screen {
                    display: flex;
                    flex-direction: column;
                    padding: $size_base_multiplier * 4 0;
                    min-height: $size_base_multiplier * 67;

                    > .kitchen-model-container {
                        margin-bottom: $size_base_multiplier * 5;
                        > .kitchen-model-text {
                            margin-bottom: $size_base_multiplier * 5;
                            p {
                                font-size: $font_size_h5;
                                white-space: pre-line;
                                @include respond-to(upto-sm) {
                                    font-size: $font_size;
                                }
                            }
                        }
                        > .kitchen-model-images {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: space-evenly;
                            > .ant-image {
                                margin: 0;
                                > img {
                                    width: auto;
                                    height: auto;
                                    max-height: $size_base_multiplier * 53;

                                    @include respond-to(xs) {
                                        max-width: $size_base_multiplier * 25;
                                    }

                                    @include respond-to(sm) {
                                        max-width: $size_base_multiplier * 27;
                                    }

                                    @include respond-to(md) {
                                        max-width: $size_base_multiplier * 38;
                                    }

                                    @include respond-to(lg) {
                                        max-width: $size_base_multiplier * 48;
                                    }
                                }
                            }
                        }
                        > .ant-alert-error {
                            background-color: transparent;
                            border-color: transparent;
                            padding: 0;
                            margin-bottom: $size_base_multiplier * 2;

                            .ant-alert-message {
                                font-weight: $font_medium;
                                font-size: 13px;
                                color: #ff4d4f;
                            }
                            > i {
                                font-size: $size_base_multiplier * 3;
                            }
                        }
                    }

                    .table-container {
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                        grid-gap: $size_base_multiplier * 3 $size_base_multiplier * 7;
                        margin-bottom: $size_base_multiplier * 5;

                        > .table-content {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            > h6 {
                                margin-bottom: $size_base_multiplier;
                                @include respond-to(upto-sm) {
                                    font-size: $font_size;
                                }
                            }
                            > p {
                                margin-bottom: 0;
                                font-size: $font_size_h6;

                                @include respond-to(upto-sm) {
                                    font-size: $font_size;
                                }
                            }
                            > .ant-image {
                                margin: $size_base_multiplier * 2 auto $size_base_multiplier * 2
                                    auto;
                            }
                        }
                    }

                    > .summary-tables-container {
                        > .summary {
                            > h4 {
                                &.main-section {
                                    display: flex;
                                    align-items: center;
                                    background-color: $colors_third;
                                    width: 100%;
                                    height: $size_base_multiplier * 5;
                                    text-align: start;
                                    padding: 0 $size_base_multiplier;
                                    margin-bottom: $size_base_multiplier * 3;

                                    @include respond-to(xs) {
                                        font-size: $font_size_h6;
                                        height: $size_base_multiplier * 8;
                                    }
                                    @include respond-to(sm) {
                                        font-size: $font_size_h6;
                                    }
                                }
                            }
                            > .colors {
                                > .table-container {
                                    display: grid;
                                    grid-template-columns: repeat(
                                        autofit,
                                        minmax($size_base_multiplier * 20, 1fr)
                                    );

                                    > .table-content {
                                        // align-items: center;
                                        > p {
                                            @include respond-to(upto-sm) {
                                                font-size: $font_size;
                                            }
                                        }
                                        > .ant-image {
                                            margin: $size_base_multiplier * 2 auto 0 auto;
                                            > img {
                                                border-radius: 10px;
                                                width: auto;
                                                height: auto;
                                                max-width: $size_base_multiplier * 15;
                                                max-height: $size_base_multiplier * 15;
                                                @include respond-to(xs) {
                                                    max-width: $size_base_multiplier * 10;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        h5 {
                            &.secondary-section {
                                display: flex;
                                align-items: center;
                                background-color: $colors_third;
                                width: 100%;
                                height: $size_base_multiplier * 4;
                                padding-left: $size_base_multiplier;
                                @include respond-to(xs) {
                                    font-size: $font_size;
                                }
                                @include respond-to(sm) {
                                    font-size: $font_size_h6;
                                }
                            }
                        }
                        h6 {
                            @include respond-to(upto-sm) {
                                font-size: $font_size;
                            }
                        }

                        > .accessories {
                            > .accessories-container {
                                > .accessories-content {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: $size_base_multiplier * 2;
                                    > .ant-image {
                                        margin-right: $size_base_multiplier * 2;
                                        > img {
                                            width: auto;
                                            height: auto;
                                            max-width: $size_base_multiplier * 15;
                                            max-height: $size_base_multiplier * 15;

                                            @include respond-to(xs) {
                                                max-width: $size_base_multiplier * 10;
                                            }
                                        }
                                    }
                                    > .accessories-text {
                                        display: flex;
                                        align-items: center;
                                        > i {
                                            font-size: $size_base_multiplier;
                                            margin-right: $size_base_multiplier;
                                        }
                                        > p {
                                            margin: 0;
                                            font-size: $font_size_h6;
                                            @include respond-to(upto-sm) {
                                                font-size: $font_size;
                                            }
                                            > span {
                                                color: $colors_fourth;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        > .quote {
                            > h5 {
                                &.secondary-section {
                                    display: flex;
                                    align-items: center;
                                    background-color: $colors_third;
                                    width: 100%;
                                    height: $size_base_multiplier * 4;
                                    text-align: start;
                                    margin-top: $size_base_multiplier * 7;
                                    padding-left: $size_base_multiplier;

                                    @include respond-to(xs) {
                                        font-size: $font_size_h6;
                                    }
                                }
                            }
                            .ant-table-container {
                                margin: $size_base_multiplier * 5 0 $size_base_multiplier * 6 0;
                                .ant-table-thead {
                                    .ant-table-cell {
                                        text-align: center;
                                        background-color: #fafafa;
                                        font-family: 'Gotham Bold';
                                        font-size: $font_size_h6;
                                        @include respond-to(upto-md) {
                                            font-size: $font_size;
                                        }
                                    }
                                }
                                .ant-table-tbody {
                                    .ant-table-cell {
                                        text-align: center;
                                        font-size: 15px;
                                        @include respond-to(upto-md) {
                                            font-size: $font_size;
                                        }
                                    }
                                }
                            }

                            > .order-note {
                                a {
                                    margin: 0 $size_base_multiplier;
                                }
                                p {
                                    font-size: $font_size_h6;
                                    @include respond-to(upto-md) {
                                        font-size: $font_size;
                                    }
                                }

                                .special_note {
                                    font-size: 12px;
                                    margin-top: 100px;
                                    white-space: pre-line;
                                }
                            }
                        }
                        .ant-divider-horizontal {
                            margin: $size_base_multiplier * 4 0;
                        }
                    }
                }
            }
        }
    }
    > footer {
        > .summary-footer {
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin: 24px 0px 0px 48px;

            @include respond-to(xs) {
                margin: $size_base_multiplier * 3 -#{$size_base_multiplier * 6} 0
                    $size_base_multiplier * 2;
            }

            .ant-btn {
                display: flex;
                align-items: center;
                justify-content: center;

                border-radius: 10px;
                padding: 0 24px 0 24px;

                &.ant-btn-circle {
                    width: 64px;
                    height: 64px;
                    padding: 2px;
                    text-align: center;
                    border-radius: 50%;
                }

                @include respond-to(xs) {
                    padding: $size_base_multiplier;
                }

                > i::before {
                    font-size: 26px;
                    font-weight: $font_regular;
                    @include respond-to(xxs) {
                        display: none;
                    }
                }

                > span {
                    font-weight: $font_semi_bold;
                    font-size: $font_size_h6;
                    @include respond-to(xs) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
.summary-modal {
    .ant-modal-content {
        @include respond-to(xs) {
            width: $size_base_multiplier * 42;
            height: $size_base_multiplier * 40;
            margin: auto;
        }
        > .ant-modal-body {
            @include respond-to(xs) {
                padding: 0 $size_base_multiplier * 2 $size_base_multiplier * 2 $size_base_multiplier *
                    2;
            }
            > .ant-card-bordered {
                > .ant-card-body {
                    > p {
                        @include respond-to(upto-sm) {
                            font-size: 13px;
                        }
                    }
                    .modal-buttons {
                        display: flex;
                        justify-content: space-around;
                        > .modal-button-container {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            > .ant-btn {
                                $size: $size_base_multiplier * 9;
                                border-radius: 50%;
                                background-color: $colors_third;
                                border-color: $colors_third;
                                width: $size;
                                height: $size;
                                margin: $size_base_multiplier * 3 0;
                                color: #000;

                                @include respond-to(xs) {
                                    $size: $size_base_multiplier * 7;
                                    width: $size;
                                    height: $size;
                                    margin: $size_base_multiplier 0;
                                }
                                > i {
                                    font-size: $size_base_multiplier * 5;
                                    color: $colors_text;
                                    @include respond-to(xs) {
                                        font-size: $size_base_multiplier * 4;
                                    }
                                }
                            }
                            > p {
                                font-size: 16px;
                                @include respond-to(upto-sm) {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
