.ant-card {
    &#selector {
        > .ant-card-body {
            .max-length-container {
                display: flex;
                justify-content: space-between;

                @include respond-to(upto-sm) {
                    flex-direction: column;
                }
                > .left-content {
                    margin-top: $size_base_multiplier * 10;
                    @include respond-to(upto-sm) {
                        margin-top: $size_base_multiplier * 2;
                    }
                    @include respond-to(from-md) {
                        width: 600px;
                    }

                    > .ant-form {
                        .input-container {
                            display: flex;
                            align-items: center;
                            .ant-input-number {
                                display: flex;
                                align-items: center;
                                height: $size_base_multiplier * 5;
                                margin-right: $size_base_multiplier * 3;
                                border-radius: 5px;

                                @include respond-to(xxs-l) {
                                    width: $size_base_multiplier * 24;
                                }
                                @include respond-to(sm-and-md) {
                                    width: $size_base_multiplier * 35;
                                }
                                @include respond-to(from-lg) {
                                    width: $size_base_multiplier * 38;
                                    height: $size_base_multiplier * 6;
                                }

                                > .ant-input-number-handler-wrap {
                                    opacity: 1;
                                    border-top-right-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                }
                                > .ant-input-number-input-wrap {
                                    width: 100%;
                                    > .ant-input-number-input::placeholder {
                                        font-size: 13px;
                                        @include respond-to(from-lg) {
                                            font-size: 15px;
                                        }
                                    }
                                }
                            }
                            .ant-btn-primary {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: $size_base_multiplier * 6;
                                border-radius: 10px;

                                &:hover {
                                    opacity: 1;
                                }
                                > i {
                                    &.ri-arrow-right-s-line::before {
                                        color: $colors_bg;
                                        font-size: $size_base_multiplier * 3;
                                    }
                                }
                            }
                        }
                        > .message {
                            > .note-container {
                                display: flex;
                                font-size: 13px;
                                margin-bottom: $size_base_multiplier;
                                > h6 {
                                    font-size: 13px;
                                    margin: 0 $size_base_multiplier 0 0;
                                }
                            }
                            > span {
                                @include respond-to(from-lg) {
                                    font-size: $font_size;
                                }
                                > b {
                                    font-weight: 900;
                                }
                                > .ant-alert {
                                    background-color: transparent;
                                    border-color: transparent;
                                    padding: 0;
                                    &.ant-alert-success {
                                        .ant-alert-message {
                                            color: #02a848;
                                            font-size: 13px;
                                            font-weight: $font_medium;
                                        }
                                    }
                                    &.ant-alert-error {
                                        .ant-alert-message {
                                            font-weight: $font_medium;
                                            font-size: 13px;
                                            color: #ff4d4f;
                                        }
                                    }
                                    > i {
                                        font-size: $size_base_multiplier * 3;
                                    }
                                }
                            }
                        }
                    }
                }
                > .image {
                    display: flex;
                    justify-content: center;
                    width: 90%;
                    > .ant-image {
                        margin-left: $size_base_multiplier * 2;
                        > .ant-image-img {
                            object-fit: contain;
                            width: auto;
                            height: auto;
                            max-height: $size_base_multiplier * 61;

                            @include respond-to(xxs) {
                                max-width: $size_base_multiplier * 40;
                                max-height: $size_base_multiplier * 28;
                            }

                            @include respond-to(xxs-l) {
                                max-width: $size_base_multiplier * 47;
                                max-height: $size_base_multiplier * 33;
                            }

                            @include respond-to(sm) {
                                max-width: $size_base_multiplier * 62;
                            }

                            @include respond-to(md) {
                                max-width: $size_base_multiplier * 60;
                            }

                            @include respond-to(from-lg) {
                                max-width: $size_base_multiplier * 70;
                            }
                        }
                    }
                }
            }
        }
    }
}
