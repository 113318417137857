.side-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    border-width: 2px;
    right: 0;
    width: $size_base_multiplier * 8;
    height: 100%;
    padding: $size_base_multiplier * 5 0;
    background-color: $colors_secondary;
    border-top-right-radius: $size_base_multiplier * 2;
    border-bottom-right-radius: $size_base_multiplier * 2;
    border: 2px solid;

    > .cart-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        > h6 {
            font-size: 13px;
        }
    }
    i {
        font-size: $size_base_multiplier * 5;
        cursor: pointer;
        &.ri-arrow-left-s-line::before {
            font-size: $size_base_multiplier * 4;
            height: $size_base_multiplier * 3;
            width: $size_base_multiplier * 3;
            border-radius: 90px;
            color: $colors_bg;
        }
    }
}

.ant-drawer-right {
    border-radius: 9px;

    > .ant-drawer-content-wrapper {
        .ant-drawer-body {
            // overflow: hidden;
            background: $colors_secondary;
            overflow-y: scroll;

            > .drawer-content {
                margin-top: $size_base_multiplier * 4;
                height: 100%;

                h6 {
                    &.sideMenu-title {
                        margin: $size_base_multiplier 0;
                    }
                }
                .sideMenu-text-container {
                    display: flex;
                    flex-direction: column;
                    margin: $size_base_multiplier 0;

                    > h6 {
                        &.sideMenu-title {
                            font-size: $font_size;
                        }
                    }
                    > .sideMenu-text {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        > p {
                            font-size: $font_size;
                            margin: 0;
                        }
                        > .ant-btn {
                            margin: $size_base_multiplier 0;
                            > i::before {
                                font-size: $size_base_multiplier * 2;
                            }
                        }
                    }
                }
                .ant-divider-horizontal {
                    margin: 0;
                }
                > .noData-container {
                    margin-top: $size_base_multiplier * 4;
                    > .ant-image {
                        margin: 0 auto $size_base_multiplier * 3 auto;
                        > img {
                            width: auto;
                            height: auto;
                            max-width: $size_base_multiplier * 30;
                            max-height: $size_base_multiplier * 21;
                        }
                    }
                    > .sideMenu-text {
                        white-space: pre-line;
                    }
                }
                > .configuration-container {
                    margin: $size_base_multiplier * 5 0 $size_base_multiplier * 5 0;
                    > h6 {
                        margin-bottom: $size_base_multiplier * 2;
                    }
                }
            }
        }
        .ant-drawer-header {
            display: flex;
            justify-content: flex-end;
            padding-right: 8px;
            background-color: $colors_secondary;
            > .ant-drawer-header-title {
                > .ant-drawer-close {
                    > .anticon {
                        > svg {
                            font-size: 22px;
                        }
                    }
                }
            }
        }
    }
}

.ant-modal {
    &.sideMenu-modal {
        .ant-modal-body {
            .ant-card-body {
                > p {
                    white-space: pre-line;
                }
                > .modal-buttons-container {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: $size_base_multiplier * 3;
                    > .ant-btn-primary {
                        border-radius: 10px;
                        margin-left: $size_base_multiplier;
                    }
                }
            }
        }
    }
}
