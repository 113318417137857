// Main card
.marinelli-app {
    padding: $summary_offsets_wrapper_padding_top $summary_offsets_wrapper_padding_top 0
        $summary_offsets_wrapper_padding_top;
    .ant-card {
        &#selector {
            border-radius: $sizes_border_radius;
            box-shadow: 5px 8px 24px 5px $colors_external_bg;
            overflow: hidden;
            padding: 0 $size_base_multiplier * 11 $summary_offsets_selector_padding_bottom
                $size_base_multiplier * 3;
            background-color: $colors_bg;
            min-height: calc(100vh - #{$summary_offsets_wrapper_padding_top * 2});

            @include respond-to(xs) {
                $offset: $size_base_multiplier * 2;
            }

            > .ant-card-body {
                padding: 0;
                .card-header {
                    > .card-title {
                        margin: $size_base_multiplier * 4 0 $size_base_multiplier * 2 0;
                        white-space: pre-line;
                        font-size: $font_size_h4;
                        font-family: 'Gotham Bold';
                        @include respond-to(upto-sm) {
                            font-size: $font_size_h5;
                        }
                        @include respond-to(from-lg) {
                            font-size: $font_size_h3;
                        }
                    }
                    > .card-description {
                        font-size: $font_size_h5;
                        margin: 0 0 $size_base_multiplier * 3 0;
                        @include respond-to(upto-sm) {
                            font-size: $font_size_h6;
                        }
                    }
                }
            }
        }
    }

    // List of Action Card's components
    .ant-card {
        &#selector {
            > .ant-card-body {
                .toggle-container {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin: $size_base_multiplier * 5 0 $size_base_multiplier * 4 0;
                    > p {
                        margin: 0 $size_base_multiplier 0 0;
                        font-size: 13px;
                        @include respond-to(from-lg) {
                            font-size: 15px;
                        }
                    }
                }
                .card-list {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                    grid-gap: $size_base_multiplier * 2 $size_base_multiplier * 5;
                    margin: 0 auto $size_base_multiplier * 4 auto;
                    max-width: $size_base_multiplier * 125;
                    justify-items: center;

                    > .action-card {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: $size_base_multiplier * 40;

                        // Normal Action cards
                        > .normal-action-card {
                            text-align: center;
                            border-radius: $sizes_border_radius;
                            border: 1px solid $colors_secondary;
                            background-color: $colors_secondary;
                            width: 100%;
                            margin: $size_base_multiplier * 6 0 0 0;

                            @include respond-to(xxs-l) {
                                width: $size_base_multiplier * 37;
                            }

                            > .ant-card-body {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                padding: 0 $size_base_multiplier * 3;
                                position: relative;
                                cursor: default;

                                > .card-header {
                                    display: flex;
                                    flex-direction: column;
                                    text-align: center;
                                    width: 100%;

                                    > h4 {
                                        &.card-title {
                                            margin: $size_base_multiplier * 3 0
                                                $size_base_multiplier 0;
                                            font-size: $font_size_h5;
                                            > span {
                                                font-size: $font_size_h5;
                                                font-family: 'Gotham Bold';
                                                @include respond-to(from-lg) {
                                                    font-size: $font_size_h4;
                                                }
                                            }
                                            @include respond-to(from-lg) {
                                                font-size: $font_size_h4;
                                            }
                                        }
                                    }

                                    > .card-description {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        margin: $size_base_multiplier 0 $size_base_multiplier 0;
                                        height: $size_base_multiplier * 5;
                                        > p {
                                            margin: 0;
                                            font-size: $font_size_h6;
                                            @include respond-to(upto-sm) {
                                                font-size: $font_size;
                                            }
                                        }

                                        > i {
                                            cursor: pointer;
                                            margin: 0 0 0 $size_base_multiplier;
                                            font-size: $font_size_h4;
                                            &::before {
                                                color: $colors_text;
                                            }
                                        }
                                    }
                                }

                                > .background-image {
                                    background-position: center;
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    width: 100%;
                                    background-color: #fff;
                                    padding-top: 100%;
                                    border-radius: $size_base_multiplier * 2;
                                    margin: $size_base_multiplier * 2 0;
                                }

                                > .hob-background-image {
                                    background-position: center;
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    width: 100%;
                                    background-color: transparent;
                                    padding-top: 100%;
                                    border-radius: $size_base_multiplier * 2;
                                    margin: $size_base_multiplier 0;
                                }
                                > .ant-btn {
                                    width: $size_base_multiplier * 26;
                                    margin: 0 auto $size_base_multiplier * 3 auto;
                                    border-radius: 10px;

                                    &:hover {
                                        opacity: 1;
                                    }

                                    > h6 {
                                        color: $colors_bg;
                                        font-weight: $font_light;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                        // Zoomable action card
                        > .zoomable-action-card {
                            border-radius: $sizes_border_radius;
                            border: 1px solid $colors_secondary;
                            background-color: $colors_secondary;
                            margin-bottom: $size_base_multiplier * 3;
                            width: 100%;
                            padding: 0 $size_base_multiplier;

                            > .ant-card-body {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                padding: 0;
                                position: relative;
                                > .ant-image {
                                    margin: $size_base_multiplier * 2$size_base_multiplier * 3;
                                    max-height: 900px;
                                    > .ant-image-img {
                                        width: auto;
                                        height: auto;
                                        max-width: $size_base_multiplier * 34;
                                        max-height: $size_base_multiplier * 30;
                                    }
                                }
                                .background-image {
                                    position: relative;
                                    width: 100%;
                                    padding-top: 100%;
                                    background-size: contain;
                                    background-color: #fff;
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    margin: $size_base_multiplier * 2 0;
                                    > .ant-image {
                                        position: absolute;
                                        top: $size_base_multiplier;
                                        right: $size_base_multiplier;
                                        $size: 40px;
                                        width: $size;
                                        height: $size;
                                        display: flex;
                                        background-color: $colors_third;
                                        justify-content: center;
                                        align-items: center;
                                        border-radius: 10px;
                                        cursor: pointer;

                                        > img {
                                            width: $size_base_multiplier * 3;
                                            height: auto;
                                        }
                                        > .ant-image-mask {
                                            display: none;
                                        }
                                    }
                                }
                                > .ant-btn {
                                    width: $size_base_multiplier * 26;
                                    margin-bottom: $size_base_multiplier * 3;
                                    border-radius: 10px;
                                    &:hover {
                                        opacity: 1;
                                    }
                                    > h6 {
                                        color: $colors_bg;
                                    }
                                }
                            }
                        }
                        > .bottom-text {
                            > p {
                                font-size: $font_size_h6;
                                margin-bottom: $size_base_multiplier;
                            }
                        }
                    }
                }
            }
        }
    }
}
