// Main body
.ant-card-body {
    .ant-row {
        margin: $size_base_multiplier * 4 0;
        > #left-column {
            margin-bottom: $size_base_multiplier * 4;
            @include respond-to(xs) {
                margin-top: $size_base_multiplier * 5;
            }
            > .slider-container {
                position: relative;
                margin-bottom: $size_base_multiplier * 5;

                > .slick-slider {
                    > .slick-list {
                        .slick-slide {
                            img {
                                margin: 0 auto;
                                width: auto;
                                height: auto;
                                max-width: 100%;
                                max-height: $size_base_multiplier * 37;
                                @include respond-to(upto-sm) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    > .slick-dots {
                        > li {
                            margin: 0;
                            > button::before {
                                font-size: 9px;
                            }
                        }
                    }
                    > .slick-arrow {
                        $size: $size_base_multiplier * 6;
                        position: absolute;
                        z-index: 1;
                        width: $size;
                        height: $size;
                        line-height: $size;
                        background-color: $colors_secondary;
                        border-radius: 50%;
                        color: $colors_fourth;
                        @include respond-to(xs) {
                            visibility: hidden;
                        }
                        @include respond-to(sm) {
                            width: $size_base_multiplier * 4;
                            height: $size_base_multiplier * 4;
                            line-height: $size_base_multiplier * 4;
                        }
                        &::before {
                            font-family: 'remixicon' !important;
                            color: inherit;
                            font-style: normal;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            font-size: $size;
                            line-height: inherit;
                            @include respond-to(sm) {
                                font-size: $size_base_multiplier * 4;
                            }
                        }
                        &.slick-prev {
                            left: 0;
                            &::before {
                                content: '\ea64';
                            }
                            &.slick-disabled {
                                visibility: hidden;
                            }
                        }
                        &.slick-next {
                            right: 0;
                            &::before {
                                content: '\ea6e';
                            }
                            &.slick-disabled {
                                visibility: hidden;
                            }
                        }
                    }
                }
            }
            .assembly-alternative {
                @include respond-to(upto-sm) {
                    font-size: 13px;
                }
                > a {
                    > u {
                        color: $colors_text;
                        margin-right: $size_base_multiplier;
                    }
                }
            }
            // COLORS STEP
            .image-container {
                display: flex;
                flex-direction: column;
                > .ant-image {
                    > img {
                        margin: 0 auto;
                        width: auto;
                        height: auto;
                        max-width: $size_base_multiplier * 57;
                        max-height: $size_base_multiplier * 51;
                        @include respond-to(upto-sm) {
                            width: 100%;
                        }
                    }
                }
                > .note-container {
                    display: flex;
                    margin-top: $size_base_multiplier * 2;
                    > h6 {
                        font-size: $font_size;
                        margin: 0 $size_base_multiplier 0 0;
                    }
                    > p {
                        margin-bottom: 0;
                        @include respond-to(upto-sm) {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
        > #right-column {
            @include respond-to(md) {
                padding-right: 0;
            }
            > .restart-container {
                display: flex;
                justify-content: flex-end;
                margin-bottom: $size_base_multiplier;

                // @include respond-to(upto-sm) {
                //     margin-top: $size_base_multiplier * 2;
                // }
                > .ant-btn {
                    display: flex;
                    align-items: center;
                    padding-right: 0;
                    > span {
                        margin: 0 $size_base_multiplier 0 0;
                        @include respond-to(upto-sm) {
                            font-size: 13px;
                        }
                    }
                    > i {
                        font-size: $font_size_h4;
                        cursor: pointer;
                        @include respond-to(upto-sm) {
                            font-size: 15px;
                        }
                    }
                }
            }
            > .ant-list {
                .ant-list-items {
                    @include respond-to(xs) {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: $size_base_multiplier $size_base_multiplier * 2;
                        justify-content: space-between;
                    }
                    @include respond-to(xxs) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    > .ant-list-item {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        background-color: $colors_secondary;
                        padding: $size_base_multiplier;
                        border-radius: $sizes_border_radius;
                        margin-bottom: $size_base_multiplier;

                        @include respond-to(xs) {
                            flex-direction: column;
                        }
                        > .image-container {
                            display: flex;
                            background-color: white;
                            border-radius: 10px;
                            width: $size_base_multiplier * 8;
                            margin: 0 $size_base_multiplier 0 0;
                            @include respond-to(xs) {
                                margin: $size_base_multiplier $size_base_multiplier 0 0;
                            }
                            > .ant-image {
                                display: flex;
                                justify-content: center;
                                width: $size_base_multiplier * 8;
                                border-radius: 10px;

                                > img {
                                    border-radius: inherit;
                                    width: auto;
                                    height: auto;
                                    max-width: $size_base_multiplier * 8;
                                    max-height: $size_base_multiplier * 8;
                                }
                                > .ant-image-mask {
                                    overflow: hidden;
                                    border-radius: inherit;
                                    &:hover {
                                        opacity: 0.4;
                                    }
                                    > .ant-image-mask-info {
                                        font-size: 0;
                                        > .anticon {
                                            font-size: $size_base_multiplier * 2;
                                        }
                                    }
                                }
                            }
                        }
                        > .checkbox-container {
                            @include respond-to(from-sm) {
                                flex: 1;
                            }

                            > .ant-checkbox-wrapper {
                                align-items: center;
                                @include respond-to(from-sm) {
                                    display: flex;
                                    flex-direction: row-reverse;
                                }
                                @include respond-to(xs) {
                                    flex-direction: column-reverse;
                                }
                                > .ant-checkbox {
                                    border-radius: 8px;
                                    top: 0;
                                    .ant-checkbox-inner {
                                        background-color: transparent;
                                        $size: $size_base_multiplier * 3;
                                        width: $size;
                                        height: $size;
                                        border-radius: 8px;
                                        border-width: 1.5px;
                                        &::after {
                                            border-color: $colors_bg;
                                            width: 8px;
                                            height: 14px;
                                        }
                                    }
                                    &.ant-checkbox-disabled {
                                        background-color: $colors_silver;
                                        + span {
                                            color: inherit;
                                        }
                                    }
                                }

                                > span:not(.ant-checkbox) {
                                    margin-bottom: 0;
                                    white-space: pre-line;
                                    @include respond-to(from-sm) {
                                        display: flex;
                                        align-items: center;
                                        flex: 1;
                                    }

                                    @include respond-to(upto-sm) {
                                        font-size: 13px;
                                        margin-right: 0;
                                    }
                                    @include respond-to(xs) {
                                        text-align: center;
                                        margin: 0 0 $size_base_multiplier 0;
                                    }
                                    > .text-container {
                                        @include respond-to(from-sm) {
                                            flex: 1;
                                        }
                                        > h6 {
                                            margin-bottom: 0;
                                            @include respond-to(sm) {
                                                font-size: $font_size;
                                            }
                                        }
                                        > p {
                                            white-space: pre-line;
                                            margin-bottom: 0;

                                            @include respond-to(upto-sm) {
                                                font-size: 13px;
                                                margin-right: 0;
                                            }
                                            @include respond-to(xs) {
                                                text-align: center;
                                                margin: 0;
                                            }
                                        }

                                        > i {
                                            font-size: $font_size_h4;
                                            cursor: pointer;
                                            margin-left: 8px;
                                        }
                                    }
                                    > .ri-information-fill {
                                        font-size: $size_base_multiplier * 3;
                                        @include respond-to(from-sm) {
                                            margin-left: $size_base_multiplier;
                                        }
                                    }
                                }

                                > span {
                                    margin-bottom: 0;
                                    white-space: pre-line;

                                    @include respond-to(upto-sm) {
                                        font-size: 13px;
                                        margin-right: 0;
                                    }
                                    @include respond-to(xs) {
                                        text-align: center;
                                        margin: 0 0 $size_base_multiplier 0;
                                    }
                                }
                            }
                        }
                        > .ant-btn-icon-only {
                            &.uncheck {
                                font-size: 18px;
                                width: 28px;
                                height: 28px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-left: $size_base_multiplier;
                                @include respond-to(xs) {
                                    position: absolute;
                                    top: $size_base_multiplier;
                                    right: $size_base_multiplier;
                                }
                                > i {
                                    font-size: inherit;
                                }
                            }
                        }
                    }
                }
            }
            // COLORS STEP
            > .ant-card {
                background-color: $colors_secondary;
                border-radius: $size_base_multiplier * 4;
                border: $colors_secondary;
                > .ant-card-body {
                    padding: $size_base_multiplier * 3;
                    @include respond-to(sm) {
                        padding: $size_base_multiplier * 3 $size_base_multiplier * 2;
                    }
                    > h6 {
                        @include respond-to(upto-sm) {
                            font-size: 14px;
                        }
                    }
                }
                .ant-dropdown-trigger {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    margin-bottom: $size_base_multiplier * 2;
                    border: solid 1px rgba(160, 160, 160, 0.32);
                    padding: 4px $size_base_multiplier;

                    > span {
                        margin-right: $size_base_multiplier;
                        color: $colors_fourth;
                        &:hover {
                            opacity: 1;
                        }
                        @include respond-to(upto-sm) {
                            font-size: 13px;
                        }
                    }
                    > i {
                        font-size: $size_base_multiplier * 3;
                        color: $colors_fourth;
                    }
                }
                .ant-list-items {
                    > .ant-list-item {
                        display: flex;

                        > .color-image-container {
                            display: flex;
                            margin-right: $size_base_multiplier * 2;
                            .ant-image {
                                border-radius: 10px;
                                border: 1px solid #ededed;

                                > img {
                                    border-radius: inherit;
                                    width: auto;
                                    height: auto;
                                    max-width: $size_base_multiplier * 10;
                                    max-height: $size_base_multiplier * 10;
                                }
                                > .ant-image-mask {
                                    overflow: hidden;
                                    border-radius: inherit;
                                    &:hover {
                                        opacity: 0.4;
                                    }
                                    > .ant-image-mask-info {
                                        font-size: 0;
                                        > .anticon {
                                            font-size: $size_base_multiplier * 2;
                                        }
                                    }
                                }
                            }
                        }
                        > .color-text-container {
                            flex: 1;

                            > * {
                                margin: 0;
                                @include respond-to(upto-sm) {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
// COLORS STEP
.ant-dropdown {
    > .ant-dropdown-menu {
        .ant-dropdown-menu-title-content {
            display: flex;
            justify-content: space-between;
            > a {
                @include respond-to(upto-sm) {
                    font-size: 13px;
                }
            }
            > .color-images-group {
                display: flex;
                justify-content: space-between;
                margin-left: $size_base_multiplier;
                > .ant-image {
                    margin-right: 5px;
                    border-radius: 10px;
                    > img {
                        width: auto;
                        height: auto;
                        max-width: $size_base_multiplier * 2;
                        max-height: $size_base_multiplier * 2;
                    }
                }
            }
        }
    }
}

// Modal with assembly alternative
.ant-modal {
    width: $size_base_multiplier * 55 !important;
    @include respond-to(xs) {
        width: $size_base_multiplier * 45 !important;
    }
    > .ant-modal-content {
        border-radius: $size_base_multiplier * 2;
        > .ant-modal-close {
            $size: 36px;
            display: flex;
            align-items: center;
            top: 10px;
            right: 10px;
            width: $size;
            height: $size;
            background-color: $colors_third;
            border-radius: 10px;
            > .ant-modal-close-x {
                color: $colors_text;
            }
        }
        > .ant-modal-body {
            padding: 0 $size_base_multiplier * 3 $size_base_multiplier * 3 $size_base_multiplier * 3;
            > .ant-card {
                border: none;
                > .ant-card-body {
                    padding: 0;
                    > h5 {
                        margin-top: $size_base_multiplier * 7;
                        @include respond-to(xs) {
                            font-size: $font_size_h6;
                        }
                    }
                    > p {
                        font-size: $font_size_h6;
                        margin-bottom: $size_base_multiplier;
                        @include respond-to(xs) {
                            font-size: $font_size;
                        }
                    }
                    > .ant-image {
                        display: flex;
                        justify-content: center;
                        margin-top: $size_base_multiplier * 2;
                        > img {
                            width: auto;
                            height: auto;
                            max-width: $size_base_multiplier * 41;
                            max-height: $size_base_multiplier * 48;
                            @include respond-to(xs) {
                                max-width: $size_base_multiplier * 35;
                                max-height: $size_base_multiplier * 45;
                            }
                        }
                    }
                }
            }
        }
        > .ant-modal-footer {
            display: none;
        }
    }
}

// Alert
.ant-notification {
    .ant-notification-notice {
        @include respond-to(xs) {
            width: $size_base_multiplier * 41;
        }
        .anticon-exclamation-circle {
            > svg {
                font-size: 28px;
                @include respond-to(xs) {
                    font-size: $size_base_multiplier * 3;
                }
            }
        }
        .ant-notification-notice-message {
            > h6 {
                margin-bottom: $size_base_multiplier;
                @include respond-to(xs) {
                    margin-bottom: 0;
                    font-size: 15px;
                }
            }
        }
        .ant-notification-notice-description {
            white-space: pre-line;

            @include respond-to(xs) {
                font-size: 13px;
            }
        }
    }
}
