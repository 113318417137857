.progress-bar-container {
    margin: $size_base_multiplier * 2 0;

    .progress-bar {
        display: flex;
        align-items: baseline;
        margin-bottom: $size_base_multiplier;

        @include respond-to(xs) {
            flex-direction: column;
            margin-bottom: $size_base_multiplier * 3;
        }
        > p {
            margin-right: $size_base_multiplier;
            margin-bottom: 0;
            @include respond-to(upto-sm) {
                font-size: 13px;
            }
        }
        > .ant-progress-steps {
            > .ant-progress-steps-outer {
                margin-left: $size_base_multiplier * 2;
                @include respond-to(xs) {
                    margin: $size_base_multiplier 0 0 0;
                }
                > .ant-progress-steps-item {
                    width: 19px;
                    height: 11px;
                }
                > span {
                    margin-left: $size_base_multiplier * 2;
                    @include respond-to(upto-sm) {
                        font-size: 13px;
                    }
                }
            }
        }
        > .ant-progress-status-success {
            .ant-progress-text {
                color: $colors_text;
            }
        }
    }
}
